<template>
    <Toast/>
    <div class="container" @click="onWrapperClick">

        <div class="layout-form-container">
            <div class="layout-main">
                <Card>
                    <template #title>
                        Delete App
                    </template>
                    <template #content>
                        <div class="grid p-fluid">
                            <div class="col-12 md:col-4">
                                <div class="field">
                                    <Dropdown v-model="selectedAccount" :options="accounts"
                                              optionLabel="accountName" placeholder="Select Account"/>
                                </div>
                            </div>
                        </div>
                        <div class="grid p-fluid">
                            <div class="col-12 md:col-4">
                                <div class="field">
                                    <Dropdown v-model="selectedApp" :options="apps"
                                              optionLabel="appName" placeholder="Select App"/>
                                </div>
                            </div>
                        </div>
                    </template>

                    <template #footer>
                        <Button icon="pi pi-check" label="Delete" @click="deleteApp()"/>

                        <Button icon="pi pi-times" label="Cancel" class="p-button-secondary" style="margin-left: .5em"
                                @click="cancelDelete()"/>
                    </template>
                </Card>
            </div>
        </div>

        <transition name="layout-mask">
            <div class="layout-mask p-component-overlay" v-if="mobileMenuActive"></div>
        </transition>
    </div>
</template>

<script>
import router from "@/router";
import AccountService from "@/service/AccountService";
import {creds} from "@/states/Cred";
import PlaystoreAppService from "@/service/PlaystoreAppsService";

export default {
    name: "CreateAccount",
    computed: {},
    created() {
        this.accountService = new AccountService();
        this.appService = new PlaystoreAppService();
    },
    mounted() {
        this.accountService.getAccounts().then(accounts => this.accounts = accounts)
    },
    data() {
        return {
            creds,
            accountService: null,
            appService: null,
            accounts: null,
            selectedAccount: null,
            selectedApp: null,
            apps: null,
            appName: null,
            packageName: null,

        }
    },

    methods: {
        getApps() {
            this.appService.getApps(this.selectedAccount._id).then(res => this.apps = res.data)
        },
        deleteApp() {

            this.appService.deleteApp(this.selectedAccount._id, this.selectedApp._id)
                .then(res => {
                    if (res.status !== 200) {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'App Delete Failed',
                            detail: res.status + " " + res.data.detail,
                            life: 3000
                        });
                    } else {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'App Deleted',
                            life: 3000
                        });

                    }
                });
        },

        cancelDelete() {
            router.push("/")
        }
    },
    components: {},
    watch: {
        'selectedAccount': function () {
            this.getApps();
        }
    },

}
</script>

<style scoped>

</style>
